.App {
  margin:0px;
  padding:0px;
  box-sizing:border-box;
  font-family: 'Montserrat', sans-serif;
}

.row{
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
}

.col{
  display:flex;
  flex-direction:column;
  flex-wrap:wrap;
}

.base{
  background-image: url('./static/background.png');
  background-repeat: repeat;
  background-size: 550px 550px;
  height:100vh;
  width:100vw;
  overflow:hidden;
  display:flex;
  flex-direction:column;
  align-items:center;
  gap:0px;
}

.card{
  width:300px;
  height:300px;
  border-radius:10px;
  box-shadow: 2px 2px 4px black;
  display:flex;
  flex-direction: column;
  gap:0px;
  align-items: center;
  padding:15px;
  background-color: #fff;
  position:relative;
  margin:30px;
}

.thumb{
  width:inherit;
  height:inherit;
  border-radius:5px;
  background-color: rgb(200, 200, 200);
  position:absolute;


}

.container{
  display:flex;
  padding:20px;
  overflow:scroll;
  flex-wrap:wrap;
  justify-content: center;
  align-items: center;
}

.nav{
  background-image: url('./static/navbg.png');
  background-repeat: repeat;
  background-size: 250px 250px;
  padding:10px;
  width:100vw;
  min-width:100vw;
  max-width:100vw;
  background-color:white;
  display:flex;
  justify-content: center;
  align-items: center;
  border-top:3px solid white;
  border-bottom:3px solid white;
  flex-direction:column;
  font-size:12px;
  font-weight:bold;
  color:white;
  text-align:center;

}

.nav>#toggle{
  display:flex;
  flex-direction: column;
  align-items: center;
  transition: 2s;
}


.nav>#toggle>p{
  margin:7px;
}

.nav >#toggle> a{
  text-decoration:underline;
  color:white;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin:5px;
 
  
}

.footer{
  background-image: url('./static/navbg.png');
  background-repeat: repeat;
  background-size: 250px 250px;
  position:sticky;
  bottom:0px;
  width:100%;
  height:20px;
  background-color:white;
  display:flex;
  justify-content: center;
  align-items: center;
  color:white;
  border-top:3px solid white;
  border-bottom:3px solid white;
  padding:1px;
}

.menu-item-name{
  height:2%;
  width:100vw;
  min-width:90vw;
  font-size:30px;
  font-weight: bold;
  color:black;
  display:flex;
  text-align:center;
  align-items: center;
  justify-content: center;
  position:relative;
  top:-60px;
  left:-30px;
  background-image: url('./static/textbg.png');
  background-size:contain;
  background-repeat:no-repeat;
  background-position: center;
  font-family: 'Lobster Two', cursive;
  padding:40px 5px;
  color:black;
  text-shadow:2px 2px white;

}

.menu-item-types-cont{
  height:2%;
  width:100vw;
  min-width:90vw;
  font-size:30px;
  font-weight: bold;
  color:black;
  display:flex;
  flex-direction:row;
  text-align:center;
  align-items: center;
  justify-content: center;
  position:relative;
  font-family: 'Lobster Two', cursive;
  padding:40px 5px;
  color:black;
  text-shadow:2px 2px white;
}

.menu-item-types{
  
  /* background-color: red; */
  width:90%;
  min-width:80%;
  font-size:19px;
  font-weight: bold;
  color:black;
  display:flex;
  align-items: center;
  justify-content: flex-end;
  position:relative;
  top:-100px;
  margin:0px;
  color:white;
  text-shadow:1px 1px 10px black;
}


.logo{
  width:90vw;
  max-width:400px;

  
  /* background-color: red; */
}


.section-header{
  min-width:100vw;
  max-width:300px;
  display:flex;
  justify-content: center;
}

.section-header > img{
  width:90vw;
  max-width:400px;
}

.banner{
  height:110px;
  width:100%;
}
